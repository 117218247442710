//获取腾讯im签名
import request from "@/untils/request.js";
//
export function getTxImSign(data) {
  return request({
    url: "/live/user/txImSign",
    method: "post",
    data,
  });
}

//系统消息
export function systemMsgIm(params) {
  return request({
    url: "/live/message/list",
    method: "get",
    params,
  });
}
//获取直播间敏感词屏蔽
export function getSensitiveWords(params) {
  return request({
    url: "/live/config/sensitiveWords",
    method: "get",
    params,
  });
}
