<template>
  <div class="home">
    <div class="top">
      <div class="nav">
        <van-tabs
          v-model:active="active"
          @click-tab="changeNav"
          :line-width="15"
          :line-height="4"
          :duration="0"
        >
          <van-tab v-for="(item, i) in navList" :key="i" :title="item.title"> </van-tab>
        </van-tabs>
      </div>
      <div class="avart">
        <img
          v-show="!isLogin"
          src="@/assets/img/icon/d_avart.png"
          alt=""
          @click="
            router.push({
              name: 'Login',
            })
          "
        />
        <img
          v-if="isLogin && userInfo.avatar"
          :src="filesCdnDomain + userInfo.avatar"
          alt=""
          @click="
            router.push({
              name: 'My',
            })
          "
        />
        <img
          v-if="isLogin && !userInfo.avatar"
          src="@/assets/img/icon/avart.png"
          alt=""
          @click="
            router.push({
              name: 'My',
            })
          "
        />
      </div>
    </div>
    <div class="content">
      <Recommend ref="ballComRef" :navActive="navActive"> </Recommend>
    </div>
    <div class="download_box">
      <DownloadApp></DownloadApp>
    </div>
    <TabBar></TabBar>
  </div>
</template>

<script setup name="Home">
import { onMounted, ref, computed, nextTick, watch, watchEffect } from "vue";
import { useRouter } from "vue-router";
import Recommend from "./homeComponents/recommend.vue";
import { liveCaregoryList } from "@/assets/api/live.js";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
const navList = ref([]);
const active = ref(0);
const navActive = ref(0);
const isLogin = ref(false);
const userInfo = ref({});
// const userInfo = computed(() => store.state.userJs.userInfo);
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const ballComRef = ref();
const basketballRef = ref();

watchEffect(() => {
  isLogin.value = localStorage.getItem("isLogin");
  userInfo.value = store.state.userJs.userInfo;
});
function changeNav(item) {
  let id = navList.value[active.value].id;

  navActive.value = id;
  nextTick(() => {
    setTimeout(() => {
      ballComRef.value._homeData();
      if (id == 0) {
        ballComRef.value._getAdsList();
      }
      if (id == 2 || id == 1 || id == 0) {
        ballComRef.value._homeMatchRecList();
      }
    }, 100);
  });
}

function _liveCaregoryList() {
  liveCaregoryList().then((res) => {
    if (!res) return;
    navList.value = res.data.filter((item) => item.status == 1);
    navList.value.unshift({
      title: "推荐",
      id: 0,
    });
  });
}
onMounted(() => {
  _liveCaregoryList();
  // nextTick(() => {
  //   //  isLogin.value = localStorage.getItem("isLogin");
  // });
});
</script>

<style scoped>
.home {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.content {
  height: calc(100% - 11.2vw - 30.93vw);
}
.top {
  height: 11.2vw;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.avart img {
  width: 8.53vw;
  height: 8.53vw;
  border-radius: 100%;
  object-fit: cover;
  margin-left: 6vw;
  margin-right: 3.73vw;
}

.nav {
  flex: 1;
  display: flex;
  align-items: center;
}
.nav_item {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 5.33vw;
}
.nav_text {
  color: #808080;
  font-weight: 400;
  font-size: 4vw;
}
.show_text {
  font-weight: 600;
  font-size: 4.8vw;
  color: #222;
}
.nav_line {
  width: 4.26vw;
  height: 4px;
  border-radius: 2px;
  background: transparent;
}
.show {
  background: #d8ad6d;
}
.download_box {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 14.93vw;
}
.home /deep/ .van-tabs__line {
  background: #d8ad6d !important;
  border-radius: 2px;
  bottom: 20px;
}
.home /deep/ .van-tab {
  padding: 0 10px;
}
</style>
