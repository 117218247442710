export const lLevel = [
  "",
  require("../assets/img/level/1.png"),
  require("../assets/img/level/2.png"),
  require("../assets/img/level/3.png"),
  require("../assets/img/level/4.png"),
  require("../assets/img/level/5.png"),
  require("../assets/img/level/6.png"),
  require("../assets/img/level/7.png"),
  require("../assets/img/level/8.png"),
  require("../assets/img/level/9.png"),
  require("../assets/img/level/10.png"),
  require("../assets/img/level/11.png"),
  require("../assets/img/level/12.png"),
  require("../assets/img/level/13.png"),
  require("../assets/img/level/14.png"),
  require("../assets/img/level/15.png"),
  require("../assets/img/level/16.png"),
  require("../assets/img/level/17.png"),
  require("../assets/img/level/18.png"),
  require("../assets/img/level/19.png"),
  require("../assets/img/level/20.png"),
  require("../assets/img/level/21.png"),
  require("../assets/img/level/22.png"),
  require("../assets/img/level/23.png"),
  require("../assets/img/level/24.png"),
  require("../assets/img/level/25.png"),
  require("../assets/img/level/26.png"),
  require("../assets/img/level/27.png"),
  require("../assets/img/level/28.png"),
  require("../assets/img/level/29.png"),
  require("../assets/img/level/30.png"),
];

export const anchorLevel = [
  "",
  require("../assets/img/anchor-level/1.png"),
  require("../assets/img/anchor-level/2.png"),
  require("../assets/img/anchor-level/3.png"),
  require("../assets/img/anchor-level/4.png"),
  require("../assets/img/anchor-level/5.png"),
  require("../assets/img/anchor-level/6.png"),
  require("../assets/img/anchor-level/7.png"),
  require("../assets/img/anchor-level/8.png"),
  require("../assets/img/anchor-level/9.png"),
  require("../assets/img/anchor-level/10.png"),
  require("../assets/img/anchor-level/11.png"),
  require("../assets/img/anchor-level/12.png"),
  require("../assets/img/anchor-level/13.png"),
  require("../assets/img/anchor-level/14.png"),
  require("../assets/img/anchor-level/15.png"),
  require("../assets/img/anchor-level/16.png"),
  require("../assets/img/anchor-level/17.png"),
  require("../assets/img/anchor-level/18.png"),
  require("../assets/img/anchor-level/19.png"),
  require("../assets/img/anchor-level/20.png"),
  require("../assets/img/anchor-level/21.png"),
  require("../assets/img/anchor-level/22.png"),
  require("../assets/img/anchor-level/23.png"),
  require("../assets/img/anchor-level/24.png"),
  require("../assets/img/anchor-level/25.png"),
  require("../assets/img/anchor-level/26.png"),
  require("../assets/img/anchor-level/27.png"),
  require("../assets/img/anchor-level/28.png"),
  require("../assets/img/anchor-level/29.png"),
  require("../assets/img/anchor-level/30.png"),
];
