<template>
  <div class="register">
    <TopBack :title="''" />
    <div class="logo_bg">
      <img src="@/assets/img/logoIn/lognBg.png" alt="" class="logo_icon" />
    </div>
    <div class="form">
      <van-form>
        <div class="phone">
          <div class="code">+86</div>
          <div class="phone_input">
            <van-field v-model="account" placeholder="请输入您的手机号码" type="tel" />
          </div>
        </div>
        <div class="p_code pwd">
          <van-field v-model="code" :type="type" placeholder="请输入验证码" />
          <button
            class="send_code"
            :disabled="codeLock"
            @click="sendCode"
            :class="codeText != '发送验证码' ? 'text_c' : ''"
          >
            {{ codeText }}
          </button>
        </div>
        <div class="pwd">
          <van-field v-model="password" :type="type" placeholder="请输入密码" />

          <img
            src="@/assets/img/icon/open.png"
            alt=""
            @click="type = 'password'"
            v-show="type == 'text'"
            class="pwd_img"
          />
          <img
            @click="type = 'text'"
            class="pwd_img"
            src="@/assets/img/icon/close.png"
            alt=""
            v-show="type == 'password'"
          />
        </div>

        <div class="forget">
          <div
            class="forget_pwd"
            @click="
              router.push({
                name: 'SetPwd',
              })
            "
          >
            忘记密码
          </div>
          <div class="register" @click="goLogin">
            已有账号？登录
            <img src="../../assets/img/icon/right_g.png" alt="" class="right_g" />
          </div>
        </div>
        <div class="sub_btn_box">
          <div class="sub_btn" @click="submitBtn">注册</div>
        </div>
      </van-form>
    </div>
    <div class="privacy">
      继续即表示您已阅读并同意鲸鱼直播
      <span @click="openVisible(1)">用户协议</span>
      和
      <span @click="openVisible(2)">隐私政策</span>
    </div>
    <van-overlay :show="visible" @click.stop="">
      <div class="back_box">
        <img
          class=""
          src="@/assets/img/icon/back.png"
          alt=""
          @click.stop="
            visible = false;
            _info = {};
          "
        />
        <div class="title">{{ _title }}</div>
      </div>
      <div class="html_box">
        <div class="html_content" v-html="_info.content"></div>
      </div>
    </van-overlay>
    <div class="download_box">
      <DownloadApp></DownloadApp>
    </div>
  </div>
</template>

<script setup name="Register">
import { protocolsInfo } from "@/assets/api/user";
import { getCode, register } from "@/assets/api/login";
import { nextTick, ref, watch } from "vue";
import { getUserInfo } from "@/assets/api/user";
import { showToast, showLoadingToast, closeToast, showFailToast } from "vant";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getTxImSign } from "@/assets/api/im";
import { chat } from "@/untils/tim";
const visible = ref(false);
const router = useRouter();
const store = useStore();
const _title = ref("");
const account = ref("");
const password = ref("");
const code = ref("");
const codeText = ref("发送验证码");
const type = ref("text");
const codeLock = ref(false);
const submitActive = ref(false);
const _info = ref({});
watch(
  [() => account.value, () => password.value, () => code.value],
  ([account, password, code]) => {
    if (code && password && account) {
      submitActive.value = true;
    } else {
      submitActive.value = false;
    }
  }
);
function openVisible(id) {
  nextTick(() => {
    _title.value = id == 1 ? "用户协议" : "隐私政策";
  });
  _protocolsInfo(id);
}
function _protocolsInfo(id) {
  protocolsInfo({
    id: id,
  }).then((res) => {
    if (!res.data) return;
    _info.value = res.data;
    visible.value = true;
  });
}
function submitBtn() {
  if (!submitActive.value) {
    showFailToast("请先填写全部信息");
    return;
  }
  showLoadingToast({
    duration: 0,
    forbidClick: true,
    message: "注册中…",
  });
  register({
    account: account.value,
    code: code.value,
    inviteCode: "",
    password: password.value,
    platform: 3,
    username: null,
  })
    .then((res) => {
      // closeToast();
      // goLogin();
      localStorage.setItem("Token", res.data.access_token);
      _getUserInfo();
    })
    .catch((err) => {
      closeToast();
    });
}

const uid = ref();
const userInfo = ref();



function _getUserInfo() {
  getUserInfo()
    .then((res) => {
      uid.value = res.data.uid;
      userInfo.value = res.data;
      localStorage.setItem("isLogin", true);
      store.commit("UPDATE_USER_INFO", res.data);
      store.commit("UPDATE_LOGIN_STATUS", true);
      store.commit("toggleAnchor", res.data.isAnchor == 1 ? true : false);
      store.commit("changYouliaoExpert", res.data.isYouliaoExpert);
      if (res.data.isPlatformAide == 1) {
        //是否是平台助理
        store.commit("changeUserIdentity", 3);
      } else {
        store.commit("changeUserIdentity", 1);
      }
    })
    .then(() => {
      getTxImSign({ imUid: uid.value.toString() }).then((result) => {
        chat()
          .login({
            userID: uid.value.toString(),
            userSig: result.data.txImSign,
          })
          .then((result) => {
            // chat()
            //   .updateMyProfile({
            //     nick: username.value,
            //     avatar: userInfo.value.avatar ? userInfo.value.avatar : "",
            //     level: userInfo.value.userLevel,
            //   })
            //   .then((res) => {})
            //   .catch((err) => {
            //     console.log("用户信息失败:" + err);
            //   });
            setTimeout(function () {
              closeToast();
              // router.back(-1);
              router.push({
                name: "Home",
              });
            }, 1000);
          })
          .catch((err) => {
            console.log("登录失败:" + err);
            closeToast();
            showDialog({
              title: "提示",
              message: "im登录失败，请刷新页面完成自动登录！",
            }).then(() => {
              window.location.reload();
            });
            router.push({
              name: "Home",
            });
          });
      });
    });
}

function goLogin() {
  router.push({
    name: "Login",
  });
}
function sendCode() {
  getCode(account.value).then((res) => {
    showToast("验证码已发送，请注意查收");
    let second = 60;
    const timer = setInterval(() => {
      second--;
      if (second) {
        codeText.value = `重新发送(${second})`;
        codeLock.value = true;
      } else {
        clearInterval(timer);
        codeText.value = `获取验证码`;
        codeLock.value = false;
      }
    }, 1000);
  });

  if (codeLock.value) {
    return;
  }
}
</script>

<style scoped>
.logo_bg {
  margin-top: 16.53vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5.33vw;
}
.logo_bg .logo_icon {
  width: auto;
  height: 13.33vw;
}

.form {
  margin-top: 16.53vw;
  padding: 0 2.66vw;
}
.phone {
  margin-bottom: 2.66vw;
  display: flex;
  height: 13.86vw;
  align-items: center;
}
.code {
  width: 19.2vw;
  height: 100%;
  background: #f6f7fa;
  display: flex;
  align-items: center;
  justify-content: center;

  border-top-left-radius: 6.93vw;
  border-bottom-left-radius: 6.93vw;
}
.phone_input {
  flex: 1;
  margin-left: 5px;
  height: 100%;
  background: #f6f7fa;
  display: flex;
  align-items: center;
  padding: 0 5.33vw;
  border-top-right-radius: 6.93vw;
  border-bottom-right-radius: 6.93vw;
}

.pwd {
  display: flex;
  align-items: center;
  padding: 0 5.33vw;
  height: 13.86vw;
  border-radius: 6.93vw;
  background: #f6f7fa;
  margin-bottom: 2.66vw;
}
.pwd .pwd_img {
  width: 6.4vw;
  height: 6.4vw;
}
.send_code {
  color: #d8ad6d;
  font-weight: 400;
  font-size: 3.73vw;
  border: none;
  background: transparent;
}
.text_c {
  color: #000;
  opacity: 0.3;
}

.forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 3.2vw;
  padding: 0 0 8vw 0;
}
.forget_pwd {
  color: #6b7280;
}
.register {
  color: #6b7280;
}
.register .right_g {
  width: 2.66vw;
  height: 2.66vw;
}

.sub_btn_box {
  padding: 0 5.33vw;
}
.sub_btn {
  color: #fff;
  border-radius: 6.93vw;
  font-weight: 600;
  font-size: 4.26vw;
  height: 13.86vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #f5d6a8, #d8ad6d);
}

.privacy {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 20vw;
  font-size: 2.66vw;
  font-weight: 400;
  color: #9ca3af;
}
.privacy span {
  color: #6b7280;
  font-weight: 500;
}

.download_box {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}
.van-overlay {
  z-index: 9999;
}
</style>
<style>
.register .van-cell__value {
  height: 100%;
}
.register .van-cell {
  position: static;
  background: #f6f7fa;
  padding: 0;
  flex: 1;
}
.register .phone_input .van-cell {
  border-top-right-radius: 6.93vw;
  border-bottom-right-radius: 6.93vw;
}
</style>
