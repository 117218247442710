import { createStore } from "vuex";
import imJs from "./modules/imJs";
import commonJs from "./modules/common";
import loginJs from "./modules/login";
import userJs from "./modules/user";
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    imJs,
    commonJs,
    loginJs,
    userJs,
  },
});
