<template>
  <div class="setPwd">
    <TopBack :title="'忘记密码'" />
    <div v-if="!nextShow">
      <div class="pwd_text">验证当前手机号</div>
      <div class="form phone_form">
        <van-form>
          <van-cell-group inset>
            <van-field
              v-model="phone"
              label="手机号码"
              type="tel"
              placeholder="请输入您的手机号"
              class="border"
            />
          </van-cell-group>
          <van-cell-group inset>
            <van-field v-model="code" label="验证码" type="number" placeholder="请输入短信验证码">
              <template #button>
                <button
                  class="send_code"
                  :disabled="codeLock"
                  @click="sendCode"
                  :class="codeText != '发送验证码' ? 'text_c' : ''"
                >
                  {{ codeText }}
                </button>
              </template>
            </van-field>
          </van-cell-group>
        </van-form>
      </div>

      <div class="sub_btn_box">
        <div class="sub_btn" @click="submitBtn">下一步</div>
      </div>
    </div>
    <div v-else>
      <div class="pwd_text">设置新的密码</div>
      <div class="form">
        <van-form>
          <div class="pwd">
            <van-field
              v-model="password"
              :type="type"
              placeholder="请输入密码"
              label="新的密码"
              :rules="[
                {
                  required: true,
                  message: '请填写密码',
                },
              ]"
            />

            <img
              src="../../assets/img/icon/open.png"
              alt=""
              @click="type = 'password'"
              v-show="type == 'text'"
            />
            <img
              @click="type = 'text'"
              src="../../assets/img/icon/close.png"
              alt=""
              v-show="type == 'password'"
            />
          </div>
        </van-form>
      </div>
      <div class="sub_btn_box">
        <div class="confirm_btn sub_btn" @click="confirmBtn">确认</div>
      </div>
    </div>
    <div class="download_box">
      <DownloadApp></DownloadApp>
    </div>
  </div>
</template>

<script setup name="SetPwd">
import { ref, watch } from "vue";
import { showToast, showFailToast } from "vant";
import { useRouter } from "vue-router";
import { getCode, setPassword } from "@/assets/api/login";
const router = useRouter();
const phone = ref("");
const password = ref("");
const code = ref("");
const type = ref("password");
const codeText = ref("发送验证码");
const codeLock = ref(false);
const nextShow = ref(false);

function submitBtn() {
  if (!phone.value) {
    showFailToast("请先填写手机号");
    return;
  }
  if (!code.value) {
    showFailToast("请先填写验证码");
    return;
  }
  nextShow.value = true;
}
function confirmBtn() {
  if (!password.value) {
    showFailToast("请先设置新的密码");
    return;
  }
  setPassword({
    code: code.value,
    password: password.value,
    account: phone.value,
  }).then((res) => {
    showToast("修改成功，请登陆");
    router.push({
      name: "Login",
    });
  });
}
function sendCode() {
  getCode(phone.value).then((res) => {
    showToast("验证码已发送，请注意查收");
    let second = 60;
    const timer = setInterval(() => {
      second--;
      if (second) {
        codeText.value = `重新发送(${second})`;
        codeLock.value = true;
      } else {
        clearInterval(timer);
        codeText.value = `获取验证码`;
        codeLock.value = false;
      }
    }, 1000);
  });

  if (codeLock.value) {
    return;
  }
}
</script>

<style scoped>
.setPwd {
  background: #fafafa;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.pwd_text {
  height: 10.13vw;
  color: #9ca3af;
  font-weight: 400;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 26px;
}
.form {
  background: #fff;
  border-radius: 8px;
  margin: 0 10px;
}
.send_code {
  color: #d8ad6d;
  font-weight: 400;
  font-size: 3.73vw;
  border: none;
  background: transparent;
}
.text_c {
  color: #000;
  opacity: 0.3;
}

.sub_btn_box {
  padding: 0 20px;
  margin-top: 20px;
  position: relative;
}
.sub_btn {
  color: #fff;
  border-radius: 6.93vw;
  font-weight: 600;
  font-size: 4.26vw;
  height: 13.86vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #fae5c7, #e5c79b);
}
.confirm_btn {
  background: linear-gradient(to right, #f5d6a8, #d8ad6d);
}

.download_box {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}
.pwd {
  display: flex;
  align-items: center;
  height: 13.86vw;
  margin-bottom: 10px;
  padding-right: 16px;
}
.pwd img {
  width: 6.4vw;
  height: 6.4vw;
}
</style>
<style>
.setPwd .van-field__label {
  margin-right: 0;
}
.setPwd .van-cell {
  position: static;
  border-radius: 8px;
}
.setPwd .phone_form .van-cell.border {
  border-bottom: 1px solid #fafafa;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.van-form {
  border-radius: 8px;
}
</style>
