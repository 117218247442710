<template>
  <div
    class="competitionItem"
    @click="
      router.push({
        name: 'Course',
        query: {
          matchId: props.item.id,
          type: props.item.type,
        },
      })
    "
  >
    <div class="competitionItem_top">
      <div class="competitionItem_top_left">
        <img src="../assets/img/icon/icon1.png" alt="" v-if="props.item.type == 1" />
        <img src="../assets/img/icon/basketball.png" alt="" v-if="props.item.type == 2" />
        <div class="competitionNameZh">
          {{ props.item.competitionNameZh }}
        </div>
      </div>
      <div class="competitionItem_top_right">{{ changeTime(props.item.matchTime) }}</div>
    </div>
    <div class="competitionItem_bottom">
      <div class="competitionItem_bottom_right">
        <div class="competitionItem_bottom_right_item">
          <img :src="props.item.homeLogo" alt="" v-if="props.item.homeLogo" class="img" />
          <div v-else class="img"></div>
          <div class="team_name">
            {{ props.item.homeNameZH }}
          </div>
        </div>
        <div class="competitionItem_bottom_right_item" style="margin-bottom: 0px">
          <img :src="props.item.awayLogo" alt="" v-if="props.item.awayLogo" class="img" />
          <div v-else class="img"></div>
          <div class="team_name">
            {{ props.item.awayNameZH }}
          </div>
        </div>
      </div>
      <div class="btn" @click.stop="reservationHandel" v-if="props.item.isBooking == 0">预约</div>

      <div class="btn cancellation" v-else @click.stop="cancellationBtn">取消</div>
    </div>
  </div>
</template>
<script setup name="competitionItem">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { showConfirmDialog, showToast, showSuccessToast } from "vant";
import { matchAddbooking, matchCancelbooking } from "@/assets/api/match";
const isLogin = ref(localStorage.getItem("isLogin"));
const store = useStore();
const router = useRouter();
const props = defineProps({
  item: Object,
});
function reservationHandel() {
  if (!isLogin.value) {
    showConfirmDialog({
      title: "提示",
      message: "未登录，是否先登录",
    })
      .then(() => {
        router.push({
          name: "Login",
        });
      })
      .catch(() => {
        // on cancel
      });
    return;
  }
  matchAddbooking({
    type: props.item.type,
    matchId: props.item.id,
  }).then((res) => {
    props.item.isBooking = 1;
    showSuccessToast("预约成功");
  });
}
function cancellationBtn() {
  if (!isLogin.value) {
    showConfirmDialog({
      title: "提示",
      message: "未登录，是否先登录",
    })
      .then(() => {
        router.push({
          name: "Login",
        });
      })
      .catch(() => {
        // on cancel
      });
    return;
  }
  matchCancelbooking({
    type: props.item.type,
    matchId: props.item.id,
  }).then((res) => {
    props.item.isBooking = 0;
    showSuccessToast("取消成功");
  });
}
function changeTime(time) {
  let date = new Date(parseInt(time * 1000));
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let M = date.getMinutes();
  M = M < 10 ? "0" + M : M;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  let dateTime = m + "-" + d + " " + h + ":" + M;
  return dateTime;
}
</script>
<style scoped>
.competitionItem {
  padding: 20px 0 0px 3.73vw;
  display: flex;
  flex-direction: column;
}
.competitionItem_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 3.2vw;
  color: #6b7280;
  padding-right: 3.2vw;
}
.team_name {
  max-width: 19vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.competitionItem_top_left {
  display: flex;
  align-items: center;
  flex: 1;
}
.competitionItem_top_right {
  white-space: nowrap;
  flex: 1;
}
.competitionItem_top_left img {
  width: 4.26vw;
  height: 4.26vw;
  margin-right: 4px;
}
.competitionNameZh {
  max-width: 20.66vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.competitionItem_bottom {
  flex: 1;
  margin-top: 12px;
  border-right: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
}
.competitionItem_bottom_right_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #000;
  font-size: 3.2vw;
  font-weight: 400;
  margin-bottom: 6px;
}
.competitionItem_bottom_right_item .img {
  /* width: 6.4vw;
  height: 6.4vw; */
  width: 24px;
  height: 24px;
  margin-right: 3.2vw;
  object-fit: cover;
  border-radius: 100%;
}

.btn {
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  width: 12.8vw;
  height: 5.86vw;
  margin-right: 4.8vw;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #f5d6a8, #d8ad6d);
}
.cancellation {
  border: 1px solid #fff;
  background: linear-gradient(180deg, #f9eed7 0%, #fff9ed 100%);

  color: #bc9877;
}
</style>
