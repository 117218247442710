<template>
  <div class="my">
    <TopBack :title="'个人资料'" />
    <div class="form" v-if="userInfo">
      <div class="avart_box form_item">
        <div class="lable">修改头像</div>
        <div class="item_left" @click="clickFile">
          <van-uploader
            :after-read="afterRead"
            :max-count="1"
            :deletable="false"
            :reupload="true"
            :before-read="beforeRead"
            accept="image/png, image/jpeg"
          >
            <img
              v-if="userInfo.avatar"
              :src="filesCdnDomain + userInfo.avatar"
              alt=""
              class="avatar"
            />
            <img v-if="!userInfo.avatar" src="@/assets/img/icon/avart.png" alt="" class="avatar" />
          </van-uploader>
          <img src="@/assets/img/icon/more.png" alt="" class="more" />
        </div>
      </div>
      <div class="middle">
        <div
          class="form_item"
          @click="
            router.push({
              name: 'NickName',
            })
          "
        >
          <div class="lable">昵称</div>
          <div class="item_left">
            <span class="nick_name"> {{ userInfo.nickName }} </span>
            <img src="@/assets/img/icon/more.png" alt="" class="more" />
          </div>
        </div>
        <div
          class="form_item"
          @click.stop="
            router.push({
              name: 'Signature',
            })
          "
        >
          <div class="lable">个性签名</div>
          <div class="item_left">
            <img src="@/assets/img/icon/more.png" alt="" class="more" />
          </div>
        </div>
        <div class="form_item" @click.stop="">
          <div class="lable">手机号</div>
          <div class="item_left">
            <span class="phone">
              {{ userInfo.account ? setPhoneXing(userInfo.account) : "" }}
            </span>
          </div>
        </div>
        <div class="form_item">
          <div class="lable">我的等级</div>
          <div class="item_left">
            <img class="anchor_level" :src="lLevel[userInfo.userLevel]" />
          </div>
        </div>
      </div>
      <div class="logout" @click="logOut">退出登录</div>
    </div>
  </div>
</template>
<script setup name="My">
import { ref, onMounted, computed, watchEffect, nextTick } from "vue";
import { useRouter } from "vue-router";
import { lLevel } from "@/untils/level-icon";
import { showToast, showLoadingToast } from "vant";
import { useStore } from "vuex";
import { editUserInfo } from "@/assets/api/user";
import { chat } from "@/untils/tim";
const store = useStore();
const router = useRouter();
const userInfo = computed(() => store.state.userJs.userInfo);
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const fileList = ref([]);

function logOut() {
  localStorage.removeItem("isLogin");
  localStorage.removeItem("Token");
  localStorage.removeItem("imChatType");
  localStorage.removeItem("imChatId");
  store.commit("UPDATE_USER_INFO", {});
  store.commit("toggleAnchor", false);
  store.commit("changYouliaoExpert", 0);
  store.commit("changeUserIdentity", 1);
  store.commit("changeUnreadCount", 0);
  chat().logout();
  router.push({
    name: "Home",
  });
}
function beforeRead(_file) {
  if (_file.type != "image/jpeg" && _file.type != "image/png") {
    showToast("请上传正确格式的格式图片");
    return false;
  }
  const isLt = _file.size / 1024 / 1024 > 10;
  if (isLt) {
    showToast("请上传10M以内的图片");
    return false;
  }
  return true;
}
const _formData = ref();
import { upLoad } from "../../untils/request";
function afterRead(_file) {
  let file = _file.file;
  let fileName = file.name; // 保存文件名
  let fileName2 = fileName.split(/\.(?=[^\.]+$)/); // 对文件名进行切割
  var renameFile = new File([file], time(new Date()) + "." + fileName2[1], {
    type: file.type,
  });
  let formData = new FormData();
  formData.append("file", renameFile);
  upLoad(formData).then((res) => {
    editUserInfo({
      avatar: res.url,
    }).then(() => {
      showToast("修改成功");
      userInfo.value.avatar = res.url;
      store.state.userJs.userInfo.avatar = res.url;
    });
  });

  //上传服务器
}
function setPhoneXing(tel) {
  var dh = tel.substr(0, 3) + "****" + tel.substr(7);
  return dh;
}

function time(time) {
  let month = time.getMonth() + 1; // 月
  let date = time.getDate(); // 日
  let hh = time.getHours(); // 时
  let mm = time.getMinutes(); // 分
  let ss = time.getSeconds(); // 秒
  if (hh >= 0 && hh < 10) {
    hh = "0" + hh;
  }
  if (mm >= 0 && mm < 10) {
    mm = "0" + mm;
  }
  if (ss >= 0 && ss < 10) {
    ss = "0" + ss;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (date < 10) {
    date = "0" + date;
  }
  return (
    time.getFullYear().toString() +
    month.toString() +
    date.toString() +
    hh.toString() +
    mm.toString() +
    ss.toString()
  );
}
</script>

<style scoped>
.my {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fafafa;
}
.form {
  padding: 3.73vw;
}
.form_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
}

.lable {
  font-size: 3.73vw;
  font-weight: 400;
  color: #222222;
}
.item_left {
  display: flex;
  align-items: center;
}
.more {
  width: 5.33vw;
  height: 5.33vw;
}
.avart_box {
  height: 18.13vw;
  border-radius: 8px;
  padding: 2.66vw 2.66vw 2.66vw 0;
}
.avart_icon {
  width: 12.8vw;
  height: 12.8vw;
  margin-right: 2.66vw;
}

.middle {
  border-radius: 2.66vw;
  background: #fff;
  margin-top: 3.73vw;
}
.form_item {
  margin: 0 2.66vw;
  border-bottom: 1px solid #fafafa;
  height: 12.8vw;
}
.form_item:last-child {
  border: none;
}
.nick_name {
  font-size: 3.73vw;
  font-weight: 600;
  color: #000;
  opacity: 0.8;
}
.phone {
  font-size: 3.73vw;
  font-weight: 600;
  color: #9ca3af;
}
.anchor_level {
  width: 10.66vw;
}

.logout {
  height: 13.06vw;
  border-radius: 2.66vw;
  background: #fff;
  line-height: 13.06vw;
  margin-top: 3.73vw;
  color: #ef4444;
  font-size: 3.73vw;
}
</style>
<style scoped>
.my /deep/ .van-uploader {
  width: 12.8vw;
  height: 12.8vw;
  margin-right: 2.66vw;
}
.my /deep/ .van-uploader__wrapper {
  width: 12.8vw;
  height: 12.8vw;
}
.my /deep/ .van-uploader__preview-image {
  width: 12.8vw;
  height: 12.8vw;
}
.my /deep/ .van-image__img {
  border-radius: 50%;
}
.avatar {
  width: 12.8vw;
  height: 12.8vw;
  border-radius: 100%;
  object-fit: cover;
}
</style>
