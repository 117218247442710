<template>
  <div
    class="liveItem"
    @click="
      router.push({
        name: 'Living',
        query: {
          id: item.anchorid,
        },
      })
    "
  >
    <div class="item_bg">
      <img :src="filesCdnDomain + item.thumb" alt="" class="bgs" v-if="item.thumb" />
      <img src="@/assets/img/home/1.png" alt="" v-else />
      <div class="living">
        <img src="@/assets/img/icon/live.gif" alt="" />
        <span>Live</span>
      </div>
      <div class="middle" v-if="props.interNetError">
        <img class="lognBg" src="@/assets/img/logoIn/lognBgW.png" alt="" />
      </div>
      <div class="num_box">
        <div class="live_title">{{ item.nickName }}</div>
        <div class="num_box_right">
          <img class="hot" src="@/assets/img/icon/hot.png" alt="" />
          <spa class="hot_num">{{ getHotNum(item.hot) }}</spa>
        </div>
      </div>
    </div>
    <div class="introduce">{{ item.title }}</div>
  </div>
</template>
<script setup name="liveItem">
import { useRouter } from "vue-router";
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const props = defineProps({
  item: Object,
  interNetError: {
    type: Boolean,
    default: false,
  },
});
</script>
<style scoped>
.liveItem:nth-child(2n) {
  margin-right: 0;
}
.liveItem {
  width: calc((96%) / 2);
  margin-right: 2.93vw;
  margin-bottom: 2.66vw;
}
.item_bg {
  height: 24vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: 2.13vw;
  border-top-right-radius: 2.13vw;
  position: relative;
  overflow: hidden;
}
.bgs {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.living {
  position: absolute;
  top: 1.6vw;
  left: 1.6vw;
  width: 9.86vw;
  height: 4vw;
  border-radius: 2px;
  background: #1f2225;
  display: flex;
  align-items: center;
  justify-content: center;
}
.living img {
  width: 2.66vw;
  height: 2.66vw;
  margin-right: 2px;
}
.living span {
  color: #e0c99c;
  font-family: PingFang SC;
  font-size: 2.66vw;
  font-weight: 400;
}

.middle {
  text-align: center;
}
.lognBg {
  width: auto;
  height: 5.33vw;
}

.num_box {
  height: 38px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1.6vw;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.live_title {
  color: #fff;
  font-size: 11px;
  font-weight: 400;
}
.num_box_right {
  vertical-align: middle;
}
.hot_num {
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
}
.hot {
  width: 6px;
  height: auto;
  margin-right: 2px;
}

.introduce {
  height: 8vw;
  border-bottom-left-radius: 2.13vw;
  border-bottom-right-radius: 2.13vw;
  background: #f3f5f6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #000;
  line-height: 8vw;
  padding: 0px 1.6vw;
  text-align: left;
}
</style>
