const userModules = {
  state: {
    userInfo: {}, //用户信息
    isAnchor: false, //是否已申请主播
    userIdentity: 1, //1：普通用户 3.平台助理  5游客
    isYouliaoExpert: 0, ///是否是有料
  },
  mutations: {
    UPDATE_USER_INFO(state, obj) {
      state.userInfo = obj;
    },
    toggleAnchor(state, bool) {
      state.isAnchor = bool;
    },
    changeUserIdentity(state, _int) {
      state.userIdentity = _int;
    },
    changYouliaoExpert(state, _int) {
      state.isYouliaoExpert = _int;
    },
  },
  actions: {
    getUserInfo({ commit }, obj) {
      commit("UPDATE_USER_INFO", obj);
    },
  },
};
export default userModules;
