<template>
  <div class="gameScreen">
    <div class="fixed">
      <div class="back_box">
        <img src="@/assets/img/icon/back.png" alt="" @click.stop="close" />
        <div class="title">赛事筛选</div>
      </div>
    </div>
    <div class="list_box">
      <van-index-bar :index-list="indexList" highlight-color="#D8AD6D">
        <div v-for="(item, i) in List" :key="i">
          <van-index-anchor :index="Object.keys(item)[0]" />
          <div class="bar_tetx_box">
            <div
              v-for="(child, j) in Object.values(item)[0]"
              :key="j"
              class="bar_tetx"
              :class="getColor(child.id) ? 'all_in' : 'all_not'"
            >
              <van-checkbox-group v-model="letterSortListCheck">
                <van-checkbox :name="child.id">
                  <p>{{ child.shortNameZh }}</p></van-checkbox
                >
              </van-checkbox-group>
            </div>
          </div>
        </div>
      </van-index-bar>
    </div>

    <div class="f_bottom">
      <div class="f_bottom_left">
        <div class="allIn" @click="selAll">全选</div>
        <div class="allNot" @click="cancleBtn">反选</div>
      </div>
      <div
        class="f_bottom_right"
        @click="confirmBtn"
        :class="letterSortListCheck.length == 0 ? 'f_bottom_right_d' : ''"
      >
        确认
      </div>
    </div>
  </div>
</template>
<script setup name="GameScreen">
import { ref, watch, watchEffect } from "vue";
import { letterSortFootBallMatch } from "@/assets/api/footBallMatch";
import { letterSortBasketballMatch } from "@/assets/api/basketBallMatch";
const emit = defineEmits(["close", "selCompetitionIds"]);
const props = defineProps({
  tabActive: {
    type: Number,
    default: 0,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const letterSortListCheck = ref([]);
const List = ref([]);
const indexList = ref([]);
const allList = ref([]);
function close() {
  indexList.value = [];
  List.value = [];
  letterSortListCheck.value = [];
  allList.value = [];
  emit("close");
}

function confirmBtn() {
  if (allList.value.length == letterSortListCheck.value.length) {
    return;
  }
  if (letterSortListCheck.value.length == 0) {
    return;
  }
  let competitionIds = letterSortListCheck.value.join(",");
  emit("selCompetitionIds", competitionIds);
  indexList.value = [];
  List.value = [];
  letterSortListCheck.value = [];
  allList.value = [];
}
function cancleBtn() {
  if (allList.value.length == letterSortListCheck.value.length) {
    letterSortListCheck.value = [];
    return;
  }
  let arr = [];
  arr = allList.value.filter((item) => !letterSortListCheck.value.some((ele) => ele === item.id));
  let arrId = [];
  arr.forEach((item) => {
    arrId.push(item.id);
  });
  letterSortListCheck.value = arrId;
}
function selAll() {
  if (allList.value.length == letterSortListCheck.value.length) {
    return;
  }
  letterSortListCheck.value = [];
  allList.value.forEach((item) => {
    letterSortListCheck.value.push(item.id);
  });
}
function _letterSortFootBallMatch() {
  let arr = [];
  letterSortFootBallMatch().then((res) => {
    res.data.forEach((element) => {
      arr = arr.concat(...Object.values(element));
      indexList.value.push(Object.keys(element)[0]);
    });
    allList.value = arr;
    arr.forEach((item) => {
      letterSortListCheck.value.push(item.id);
    });
    List.value = res.data;
  });
}
function _letterSortBasketballMatch() {
  let arr = [];
  letterSortBasketballMatch().then((res) => {
    res.data.forEach((element) => {
      arr = arr.concat(...Object.values(element));
      indexList.value.push(Object.keys(element)[0]);
    });
    allList.value = arr;
    arr.forEach((item) => {
      letterSortListCheck.value.push(item.id);
    });
    List.value = res.data;
  });
}
watchEffect(() => {
  if (props.visible) {
    if (props.tabActive == 0) {
      _letterSortFootBallMatch();
    } else {
      _letterSortBasketballMatch();
    }
  }
});
function getColor(id) {
  if (letterSortListCheck.value.indexOf(id) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
<style scoped>
.gameScreen {
  height: 100vh;
  background: #fafafa;
}
.fixed {
  height: 10.66vw;
}
.list_box {
  height: calc(100vh - 26.11vw);
  overflow-y: scroll;
  position: relative;
}

.bar_tetx_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 2.66vw;
}
.gameScreen .bar_tetx {
  display: inline-block;
  width: 32vw;
  padding: 0 2.66vw;
  height: 9.6vw;
  line-height: 9.6vw;

  background: #fff;
  margin: 0 2.66vw 2.66vw 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.gameScreen .bar_tetx p {
  margin-left: 8px;
  font-size: 3.73vw;
  font-weight: 400;
}

.f_bottom {
  z-index: 9;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  height: 10.13vw;
  padding: 2.66vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.f_bottom_left {
  display: flex;
  align-items: center;
  font-size: 4.26vw;
  font-weight: 400;
}
.allIn {
  color: #d8ad6d;
  margin-right: 5.86vw;
}
.allNot {
  color: #9ca3af;
}

.f_bottom_right {
  border-radius: 5.06vw;
  height: 10.13vw;
  width: 23.46vw;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #d8ad6d 0%, #f5d6a8 100%);
}
.f_bottom_right_d {
  background: linear-gradient(270deg, #e5c79b 0%, #fae5c7 100%);
}
</style>
<style scoped>
.gameScreen /deep/ .van-index-anchor {
  text-align: left;
  color: #a9a9a9;
  font-weight: 400;
  height: 10.66vw;
  line-height: 10.66vw;
}
.gameScreen /deep/ .van-index-anchor--sticky {
  background: #fafafa !important;
}
.gameScreen /deep/ .van-index-bar__index {
  line-height: 4.53vw;
  font-size: 3.2vw;
  font-weight: 500;
}
.list_box::-webkit-scrollbar {
  display: none;
}
.gameScreen /deep/ .van-hairline--bottom:after {
  border-bottom: none;
}
.gameScreen /deep/ .van-checkbox__icon {
  height: auto;
}
.gameScreen /deep/ .van-checkbox__icon .van-icon {
  width: 3.2vw;
  height: 3.2vw;
  line-height: 3.2vw;
  font-size: 6px;
}
.gameScreen /deep/ .van-checkbox__icon--checked .van-icon {
  background-color: #d8ad6d;
  border-color: #d8ad6d;
}
.all_in /deep/ .van-checkbox__label {
  color: #d8ad6d;
}
.all_not /deep/ .van-checkbox__label {
  color: #9ca3af;
}
</style>
