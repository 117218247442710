<template>
  <div class="downloadApp">
    <img src="../assets/img/logoIn/logo.png" alt="" />
    <div class="down_text">
      <div class="downloadApp_title">下载虎鲸APP</div>
      <div class="text_s">实时高清直播，触手可及</div>
    </div>
    <div
      class="down_btn"
      @click="
        router.push({
          name: 'DownLoad',
        })
      "
    >
      立即下载
    </div>
  </div>
</template>

<script name="downloadApp" setup>
import { useRouter } from "vue-router";
const router = useRouter();
</script>
<style scoped>
.downloadApp {
  height: 16vw;
  background: #2b2e3c;
  opacity: 0.92;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.downloadApp img {
  width: 10.66vw;
  height: 10.66vw;
}
.down_text {
  flex: 1;
  color: #fff;
  text-align: left;
  margin-left: 10px;
}
.downloadApp_title {
  font-weight: 600;
  font-size: 3.46vw;
  line-height: 4.8vw;
}
.text_s {
  font-weight: 400;
  font-size: 11px;
  line-height: 3.73vw;
}

.down_btn {
  width: 20.26vw;
  height: 7.46vw;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #d8ad6d, #f5d6a8);
  font-weight: 600;
  font-size: 3.73vw;
  color: #fff;
}
</style>
