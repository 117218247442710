<template>
  <div class="nickName">
    <TopBack :title="'修改昵称'" :saveShow="true" :values="nicekName" @saveBtn="saveHandle" />
    <div class="form">
      <van-cell-group inset>
        <van-field
          v-model="nicekName"
          autosize
          maxlength="12"
          placeholder="请输入昵称"
          show-word-limit
        />
      </van-cell-group>
      <div class="tip">昵称最多12个字符，30天内只能修改一次</div>
    </div>
  </div>
</template>
<script setup name="NickName">
import { showToast } from "vant";
import { onMounted, ref, computed, nextTick } from "vue";
import { useStore } from "vuex";
import { editUserInfo } from "@/assets/api/user";
const store = useStore();
const userInfo = computed(() => store.state.userJs.userInfo);
const nicekName = ref("");
function saveHandle() {
  editUserInfo({
    nickName: nicekName.value,
  }).then((res) => {
    showToast("修改成功");
    store.state.userJs.userInfo.nickName = nicekName.value;
  });
}
onMounted(() => {
  nicekName.value = userInfo.value.nickName;
});
</script>

<style scoped>
.nickName {
  background: #fafafa;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.form {
  margin: 5.33vw 2.66vw;
}
.tip {
  color: #9ca3af;
  font-size: 3.46vw;
  font-weight: 400;
  padding: 2.66vw 0;
  text-align: left;
}
</style>
<style scoped>
.nickName .van-field {
  border-radius: 8px;
}
.nickName /deep/ .van-field__word-num {
  color: #d8ad6d;
}
</style>
