// // 腾讯即时通信
// import TIM from 'tim-js-sdk';
// // 创建 SDK 实例，TIM.create() 方法对于同一个 SDKAppID 只会返回同一份实例
// let options = {
//     SDKAppID: 0 // 接入时需要将0替换为您的即时通信应用的 1400740966
// };
// let tim = TIM.create(options); // SDK 实例通常用 tim 表示
// // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
// tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
// // tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用

// // 注册 COS SDK 插件
// export default tim
import TIMUploadPlugin from "tim-upload-plugin";
import TencentCloudChat from "@tencentcloud/chat";
export function chat() {
  let tim;
  let options;
  options = {
    SDKAppID: localStorage.getItem("AppID"), // 接入您的即时通信应用 1400697820
  };
  tim = TencentCloudChat.create(options);
  tim.setLogLevel(1);
  tim.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });
  return tim;
}
// import { filterFn } from "./index";
export function getMessageForShow(sensitiveWordsArr, _lastMessage) {
  let _data;
  let result;
  if (_lastMessage.type == "TIMCustomElem") {
    _data = JSON.parse(_lastMessage.payload.data);

    if (_data.action == "IMChatRedPocket") {
      result = "【红包】";
    } else if (_data.action == "IMChatRecPocketGain") {
      result = _data.pocketName + "领取了红包";
    } else {
      result = "【这是一条系统消息】";
    }
  } else if (_lastMessage.type == "TIMGroupTipElem") {
    result = "【这是一条系统消息】";
  } else if (_lastMessage.type == "TIMImageElem") {
    result = "【图片】";
  } else if (_lastMessage.type == "TIMVideoFileElem") {
    result = "【视频】";
  } else {
    // result = dataImgFilte(filterFn(sensitiveWordsArr, _lastMessage.messageForShow));
    result = dataImgFilte(_lastMessage.messageForShow);
  }
  return result;
}
import { emojiMap } from "./emojiMap";
function dataImgFilte(val) {
  var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
  return val
    .replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
      // console.log(arrEntities);
      return arrEntities[t];
    })
    .replace(/\[[^\]]+\]/g, function (tmp) {
      var img = `<img src='${emojiMap[tmp]}' style="width: 20px;height: 20px;vertical-align:middle">`;
      return img;
    });
}
