import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Msg from "../views/message/msg.vue";
import Match from "../views/match/match.vue";
import Login from "../views/logoIn/login.vue";
import Register from "../views/logoIn/register.vue";
import SetPwd from "../views/logoIn/setPwd.vue";
import My from "../views/my/my.vue";
import NickName from "../views/my/nickName.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/msg",
    name: "Msg",
    component: Msg,
    // meta: { keepAlive:true},
  },
  {
    path: "/match",
    name: "Match",
    component: Match,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/setPwd",
    name: "SetPwd",
    component: SetPwd,
    meta: {
      title: "忘记密码",
    },
  },
  {
    path: "/my",
    name: "My",
    component: My,
  },
  {
    path: "/nickName",
    name: "NickName",
    component: NickName,
  },
  {
    path: "/signature",
    name: "Signature",
    component: () => import("../views/my/signature.vue"),
  },
  {
    path: "/course",
    name: "Course",
    component: () => import("../views/course/course.vue"),
  },
  {
    path: "/gameScreen",
    name: "GameScreen",
    component: () => import("../views/gameScreen/index.vue"),
  },
  {
    path: "/msgDetails",
    name: "MsgDetails",
    component: () => import("../views/message/msgDetails.vue"),
  },
  {
    path: "/groupInfo",
    name: "GroupInfo",
    component: () => import("../views/message/groupInfo.vue"),
  },
  {
    path: "/groupMembers",
    name: "GroupMembers",
    component: () => import("../views/message/groupMembers.vue"),
  },
  {
    path: "/editNickName",
    name: "EditNickName",
    component: () => import("../views/message/editNickName.vue"),
  },
  {
    path: "/grounpNotice",
    name: "GrounpNotice",
    component: () => import("../views/message/grounpNotice.vue"),
  },
  {
    path: "/personInfo",
    name: "PersonInfo",
    component: () => import("../views/message/personInfo.vue"),
  },
  {
    path: "/personRemark",
    name: "PersonRemark",
    component: () => import("../views/message/personRemark.vue"),
  },
  {
    path: "/systemMsg",
    name: "SystemMsg",
    component: () => import("../views/message/systemMsg.vue"),
  },
  {
    path: "/selectPerson",
    name: "SelectPerson",
    component: () => import("../views/message/selectPerson.vue"),
  },
  {
    path: "/living",
    name: "Living",
    component: () => import("../views/live/live.vue"),
  },
  {
    path: "/downLoad",
    name: "DownLoad",
    component: () => import("../views/downLoad/index.vue"),
  },
];

const router = createRouter({
  mode: "hash",
  base: "/dist",
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory("/"),
  routes,
});

export default router;
