<template>
  <van-loading vertical>
    <template #icon>
      <img src="@/assets/img/live/loding.gif" alt="" class="loding" />
    </template>
    正在加载中...
  </van-loading>
</template>
<style scoped>
.van-loading {
  justify-content: center;
  height: 100vh;
}
.van-loading /deep/ .van-loading__spinner {
  animation: none;
}
.loding {
  width: 10vw;
  height: auto;
}
</style>
