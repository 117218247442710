<template>
  <div class="footballMatch">
    <div
      class="footballMatch_item"
      @click="
        router.push({
          name: 'Course',
          query: {
            matchId: props.item.id,
            type: 1,
          },
        })
      "
    >
      <div class="footballMatch_item_top">
        <div class="footballMatch_item_top_left">
          <div class="footballMatch_item_top_left_name" :style="{ background: getColor() }">
            {{ props.item.competitionNameZh }}
          </div>
          <div class="footballMatch_item_top_left_time">{{ changeTime(props.item.matchTime) }}</div>
        </div>
        <div class="footballMatch_item_top_right">
          半{{ props.item.homeHalfScore }}-{{ props.item.awayHalfScore }} 角{{
            props.item.homeCornerKick
          }}
          - {{ props.item.awayCornerKick }}
        </div>
        <div class="imgs" v-if="props.item.anchors && props.item.anchors.length > 0">
          <div class="avatar_" v-for="(item, i) in props.item.anchors" :key="i">
            <img
              :src="filesCdnDomain + item.avatar"
              alt=""
              v-if="item.avatar"
              class="footballMatch_item_bottom_right"
              @click.stop="goLiving(item)"
            />
            <img
              src="@/assets/img/icon/avart.png"
              alt=""
              class="footballMatch_item_bottom_right"
              v-else
              @click.stop="goLiving(item)"
            />
          </div>
        </div>
      </div>
      <div class="footballMatch_item_bottom">
        <img
          @click.stop="cancel"
          src="@/assets/img/icon/save_a.png"
          alt=""
          v-if="props.item.isCollect == 1"
          class="footballMatch_item_bottom_left"
        />
        <img
          @click.stop="collect"
          v-else
          src="@/assets/img/icon/save.png"
          alt=""
          class="footballMatch_item_bottom_left"
        />
        <div class="footballMatch_item_bottom_middle">
          <div class="left_box">
            <div class="flex left">
              <div
                class="ratio"
                style="background: #ffd600; margin-right: 2px"
                v-if="props.item.homeYellowCard > 0"
              >
                {{ props.item.homeYellowCard }}
              </div>
              <div
                class="ratio"
                style="background: #e52e2e; margin-right: 2px"
                v-if="props.item.homeRedCard > 0"
              >
                {{ props.item.homeRedCard }}
              </div>
              <span class="name" style="max-width: 70px">
                <div class="qp" style="max-width: 70px">{{ props.item.homeNameZH }}</div>
                <div
                  class="w"
                  v-if="props.item.homeNameZH && props.item.homeNameZH.length > 5"
                ></div>
              </span>
              <div class="score m_l" v-if="props.item.status == 1">-</div>
              <div class="score m_l" v-else>{{ props.item.homeScore }}</div>
            </div>
          </div>

          <div class="center_box">
            <div class="center1" v-if="props.item.status == 0">异常</div>
            <div class="center1" v-if="props.item.status == 1">未开</div>
            <div class="center" v-if="props.item.status > 1 && props.item.status < 8">
              {{ props.item.duration }}
            </div>
            <div class="center1" v-if="props.item.status == 8">完场</div>
            <div class="center1" v-if="props.item.status == 9">推迟</div>
            <div class="center1" v-if="props.item.status == 10">中断</div>
            <div class="center1" v-if="props.item.status == 11">腰斩</div>
            <div class="center1" v-if="props.item.status == 12">取消</div>
            <div class="center1" v-if="props.item.status == 13">待定</div>
          </div>
          <div class="right_box">
            <div class="right flex">
              <div class="score m_r" v-if="props.item.status == 1">-</div>
              <div class="score m_r" v-else>{{ props.item.awayScore }}</div>

              <span class="name" style="max-width: 70px">
                <div class="qp" style="max-width: 70px">{{ props.item.awayNameZH }}</div>
                <div
                  class="w"
                  v-if="props.item.awayNameZH && props.item.awayNameZH.length > 5"
                ></div>
              </span>
              <div
                class="ratio"
                style="background: #e52e2e; margin-left: 2px"
                v-if="props.item.awayRedCard > 0"
              >
                {{ props.item.awayRedCard }}
              </div>
              <div
                class="ratio"
                style="background: #ffd600; margin-left: 2px"
                v-if="props.item.awayYellowCard > 0"
              >
                {{ props.item.awayYellowCard }}
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <img
            src="@/assets/img/icon/icon5.png"
            alt=""
            class="footballMatch_item_bottom_right"
            v-if="
              props.item.isAnimation == 1 && (!props.item.anchors || props.item.anchors.length == 0)
            "
          />
          <img
            src="@/assets/img/icon/icon6.png"
            alt=""
            class="footballMatch_item_bottom_right"
            v-if="props.item.isLive == 1 && (!props.item.anchors || props.item.anchors.length == 0)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup name="FootballMatch">
import { useRouter } from "vue-router";
import { showFailToast, showSuccessToast, showConfirmDialog } from "vant";
import { addCollect, cancelCollect } from "@/assets/api/match";
import { useStore } from "vuex";
import { onMounted, ref, computed } from "vue";
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const isLogin = ref(localStorage.getItem("isLogin"));
const store = useStore();
const router = useRouter();
const props = defineProps({
  j: Number,
  item: Object,
});

function getColor() {
  if (props.j == 0 || props.j % 4 == 0) return "#6C56C2";
  if (props.j % 4 == 1) return "#659FF6";
  if (props.j % 4 == 2) return "#7FC256";
  if (props.j % 4 == 3) return "#DC8E46";
}
function changeTime(time) {
  let date = new Date(parseInt(time * 1000));
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let M = date.getMinutes();
  M = M < 10 ? "0" + M : M;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  let dateTime = h + ":" + M;
  return dateTime;
}
function collect() {
  if (!isLogin.value) {
    showConfirmDialog({
      title: "提示",
      message: "未登录，是否先登录",
    })
      .then(() => {
        router.push({
          name: "Login",
        });
      })
      .catch(() => {
        // on cancel
      });
    return;
  }
  addCollect({ type: 1, matchId: props.item.id }).then((res) => {
    showSuccessToast("收藏成功");
    props.item.isCollect = 1;
  });
}
function cancel() {
  if (!isLogin.value) {
    showConfirmDialog({
      title: "提示",
      message: "未登录，是否先登录",
    })
      .then(() => {
        router.push({
          name: "Login",
        });
      })
      .catch(() => {
        // on cancel
      });
    return;
  }
  cancelCollect({ type: 1, matchId: props.item.id }).then((res) => {
    showSuccessToast("已取消收藏");
    props.item.isCollect = 0;
  });
}
function goLiving(item) {
  if (item.isLive == 0) {
    showFailToast("主播暂未开播");
    return;
  }
  router.push({
    name: "Living",
    query: {
      id: item.uid,
    },
  });
}
</script>

<style scoped>
.footballMatch {
  margin-top: 2.66vw;
}
.footballMatch_item {
  border-radius: 8px;
  background: #fff;
  padding: 6px;
}
.footballMatch_item_top {
  border-bottom: 1px solid #f3f5f6;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
}
.footballMatch_item_top_left {
  width: 36.8vw;
  display: flex;
  align-items: center;
}
.footballMatch_item_top_left_name {
  height: 4.8vw;
  font-size: 2.66vw;
  color: #fff;
  border-radius: 4px;
  width: 14.93vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.footballMatch_item_top_left_time {
  font-size: 2.93vw;
  font-weight: 400;
  color: #a9a9a9;
}

.footballMatch_item_top_right {
  color: #a9a9a9;
  font-size: 2.93vw;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.footballMatch_item_bottom {
  display: flex;
  align-items: center;
  padding: 3.73vw 0;
}
.footballMatch_item_bottom_left {
  width: 5.33vw;
  height: 5.33vw;
}
.footballMatch_item_bottom_middle {
  flex: 1;
  margin: 0 4.26vw 0 4.26vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex {
  display: flex;
  align-items: center;
}
.score {
  color: #da8b15;
  font-size: 4vw;
  font-weight: 500;
}
.m_l {
  margin-left: 4px;
}
.m_r {
  margin-right: 4px;
}
.ratio {
  color: #fff;
  text-align: center;
  font-family: SF Pro;
  font-size: 2.66vw;
  font-weight: 500;
  margin-right: 3px;
  padding: 3px;
  border-radius: 1px;
}
.name {
  color: #222;
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 500;
  max-width: 32vw;
  position: relative;
  display: flex;
}
.qp {
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  max-width: 32vw;
  /* height: 5.33vw; */
}
.w {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 2.66vw;
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}
.line {
  color: #6b7280;
  font-size: 4vw;
  font-weight: 500;
}
.left_box {
  flex: 1;
}
.left {
  justify-content: flex-end;
}

.center_box {
  margin: 0 3px;
  font-size: 3.2vw;
  font-family: SF Pro;
}
.center {
  display: inline-block;
  background: #fde4c0;
  color: #9e7230;
  padding: 3px 8px;
  font-weight: 500;
  border-radius: 4px;
}
.center1 {
  color: #b4b8be;
  padding: 3px 8px;
  font-weight: 400;
  border-radius: 4px;
  background: #f3f5f6;
}

.right_box {
  flex: 1;
}
.righ .ratio {
  margin-right: 0;
  margin-left: 3px;
}
.avatar_ .footballMatch_item_bottom_right {
  border-radius: 100%;
}
.footballMatch_item_bottom_right {
  width: 5.33vw;
  height: 5.33vw;
}
.imgs {
  display: flex;
  align-items: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.avatar_ {
  position: relative;
  z-index: 1;
  width: 5.33vw;
  height: 5.33vw;
  border-radius: 100%;
  border: 2px solid #fff;
}

.avatar_ {
  margin-left: -4px;
  z-index: 0;
}
</style>
<style>
/*修改滚动条样式*/

.qp::-webkit-scrollbar {
  display: none;
}
</style>
