import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "vant/lib/index.css";
import {
  Form,
  Overlay,
  Field,
  Button,
  Toast,
  Uploader,
  List,
  PullRefresh,
  Swipe,
  SwipeItem,
  IndexBar,
  IndexAnchor,
  Cell,
  Checkbox,
  Dialog,
  Switch,
  Search,
  ActionSheet,
  Popover,
  Popup,
  Loading,
  Skeleton,
  CheckboxGroup,
  Tab,
  Tabs,
} from "vant";
import DownloadApp from "./components/downloadApp.vue";
import TabBar from "./components/tabBar.vue";
import TopBack from "./components/topBack.vue";
import LiveItem from "./components/liveItem.vue";
import LoadingCom from "./components/loading.vue";
import { imTime, getHotNum } from "./untils/index";
const app = createApp(App);
app.config.globalProperties.imTime = imTime;
app.config.globalProperties.getHotNum = getHotNum;
// 全局组件挂载
app.component("DownloadApp", DownloadApp);
app.component("LoadingCom", LoadingCom);
app.component("TabBar", TabBar);
app.component("TopBack", TopBack);
app.component("LiveItem", LiveItem);
app.use(Form);
app.use(Tab);
app.use(Tabs);
app.use(Skeleton);
app.use(CheckboxGroup);
app.use(Overlay);
app.use(Loading);
app.use(Popup);
app.use(ActionSheet);
app.use(Popover);
app.use(Search);
app.use(Switch);
app.use(Field);
app.use(Button);
app.use(List);
app.use(PullRefresh);
app.use(Toast);
app.use(Uploader);
app.use(Swipe);
app.use(SwipeItem);
app.use(IndexBar);
app.use(Cell);
app.use(Dialog);
app.use(Checkbox);
app.use(IndexAnchor);
app.use(store).use(router).mount("#app");
