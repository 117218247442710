const commonModules = {
  state: {
    filesCdnDomain: "", //图片地址前缀
    initInfo:{}// 平台信息
  },
  mutations: {
    UPDATE_FILES_CDN_DOMAIN(state, filesCdnDomain) {
      state.filesCdnDomain = filesCdnDomain;
    },
    getInitInfo(state,obj){
      state.initInfo = obj;
    }
  },
  actions: {
    updateFilesCdnDomain({ commit }, filesCdnDomain) {
      commit("UPDATE_FILES_CDN_DOMAIN", filesCdnDomain);
    },
  },
};
export default commonModules;
