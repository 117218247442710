import request from "@/untils/request.js";
//全部
export function allBasketballMatch(params) {
  return request({
    url: "/live/match/basketball/all",
    method: "get",
    params,
  });
}
//热门
export function hotBasketballMatch(params) {
  return request({
    url: "/live/match/basketball/hot",
    method: "get",
    params,
  });
}
////赛程
export function courseBasketballMatch(params) {
  return request({
    url: "/live/match/basketball/course",
    method: "get",
    params,
  });
}
///赛果
export function resultBasketballMatch(params) {
  return request({
    url: "/live/match/basketball/result",
    method: "get",
    params,
  });
}
//进行
export function playingBasketballMatch(params) {
  return request({
    url: "/live/match/basketball/playing",
    method: "get",
    params,
  });
}
///字母筛选
export function letterSortBasketballMatch(params) {
  return request({
    url: "/live/match/basketball/competition/letterSort/list",
    method: "get",
    params,
  });
}
//篮球-赛况文字直播
export function basketballTliveList(params) {
  return request({
    url: "/live/match/basketball/tlive/list",
    method: "get",
    params,
  });
}
//赛事详情
export function basketballInfo(matchId) {
  return request({
    url: "/live/match/basketball/info/" + matchId,
    method: "get",
    params: {
      platform: 3,
    },
  });
}
