import request from "@/untils/request.js";
//赛事-预约
export function matchAddbooking(data) {
  return request({
    url: "/live/match/booking/add",
    method: "post",
    data,
  });
}
//赛事-取消预约
export function matchCancelbooking(data) {
  return request({
    url: "/live/match/booking/cancel",
    method: "post",
    data,
  });
}
//赛事-收藏
export function addCollect(data) {
  return request({
    url: "/live/match/collect/add",
    method: "post",
    data,
  });
}
//赛事-取消收藏
export function cancelCollect(data) {
  return request({
    url: "/live/match/collect/cancel",
    method: "post",
    data,
  });
}
//获取本场赛事直播列表
export function matchLiveList(params) {
  //matchId   type  1-足球 2-篮球
  return request({
    url: "/live/match/live/list",
    method: "get",
    params,
  });
}
