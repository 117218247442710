<template>
  <div class="recommend">
    <LoadingCom v-if="loading" style="height: calc(100vh - 11.2vw)" />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-else>
      <div class="r_top" v-if="props.navActive == 0">
        <van-swipe
          class="my-swipe"
          indicator-color="white"
          v-if="adsList.length > 0"
          :autoplay="3000"
        >
          <van-swipe-item v-for="child in adsList" :key="child.id">
            <img :src="filesCdnDomain + child.imageUrl" alt="" />
          </van-swipe-item>
        </van-swipe>
        <div class="my-swipe" v-else>
          <img src="@/assets/img/home/2.png" alt="" />
        </div>
      </div>
      <div class="competition" v-if="matchRecList.length > 0">
        <van-swipe :show-indicators="false" :loop="false" :width="212">
          <van-swipe-item v-for="child in matchRecList" :key="child" class="competition_item">
            <CompetitionItem :item="child" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <van-list
        v-model:loading="listLoading"
        :finished="finished"
        :finished-text="hotliveList.length > 0 ? '没有更多了' : ''"
        @load="onLoad"
      >
        <h4 class="title"><img src="@/assets/img/icon/icon.png" alt="" />正在热播</h4>
        <div class="list" v-if="hotliveList.length > 0">
          <LiveItem
            :interNetError="interNetError"
            v-for="item in hotliveList"
            :key="item.liveid"
            :item="item"
          />
        </div>
        <div class="empty" v-else>
          <img src="@/assets/img/home/empty.png" alt="" class="empty_img" />
          <div class="empty_text">暂无数据</div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script setup name="Recommend">
import { showToast, showLoadingToast, closeToast } from "vant";
import CompetitionItem from "../../components/competitionItem.vue";
import { onMounted, ref, computed, watch } from "vue";
import { getHomeData, homeMatchRecList, getAdsList } from "@/assets/api/home.js";
import { useStore } from "vuex";
const store = useStore();
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const loading = ref(true);
const finished = ref(false);
const refreshing = ref(false);
const hotliveList = ref([]);
const total = ref(0);
const pageNum = ref(1);
const interNetError = ref(true);
const matchRecList = ref([]);
const adsList = ref([]);
const listLoading = ref(true);
watch(
  () => props.navActive,
  (val) => {
    hotliveList.value = [];
    matchRecList.value = [];
    pageNum.value = 1;
    finished.value = false;
    loading.value = true;
  }
);
const props = defineProps({
  navActive: {
    type: Number,
    default: 0,
  },
});
function onLoad() {
 console.log("我看看进来了没有");
  if (!finished.value) {
    _homeData();
  }
}
function onRefresh() {
  refreshing.value = true;
  hotliveList.value = [];
  pageNum.value = 1;
  // 清空列表数据
  finished.value = false;

  // 将 loading 设置为 true，表示处于加载状态
  loading.value = true;
  // 重新加载数据
  _homeData();
}
defineExpose({
  _homeData,
  _homeMatchRecList,
  _getAdsList,
});
function _homeData() {
  let query = {
    categoryId: props.navActive,
    pageNum: pageNum.value,
    pageSize: 10,
    platform: 3,
  };
  getHomeData(query)
    .then((res) => {
      interNetError.value = false;
      loading.value = false;
      listLoading.value = false;
      refreshing.value = false;
      total.value = res.total;
      hotliveList.value = hotliveList.value.concat(res.data);
      if (hotliveList.value.length == res.total) {
        finished.value = true;
      } else {
        pageNum.value++;
      }
    })
    .catch((err) => {
      loading.value = false;
      listLoading.value = false;
      refreshing.value = false;
      interNetError.value = true;
      finished.value = true;
    });
}
function _homeMatchRecList() {
  homeMatchRecList(props.navActive).then((res) => {
    matchRecList.value = res.data;
  });
}
function _getAdsList() {
  getAdsList().then((res) => {
    adsList.value = res.data;
  });
}
onMounted(() => {
  _homeData();
  // onLoad();
  _homeMatchRecList();
  _getAdsList();
});
</script>
<style scoped>
.recommend {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.r_top {
  padding: 4px 14px 0;
}
.my-swipe {
  width: 100%;
}
.my-swipe img {
  width: 100%;
  height: 147px;
  border-radius: 12px;
}

.competition {
  background: linear-gradient(180deg, rgba(245, 246, 246, 0) 0%, #f5f6f6 100%);
  width: 100%;
  margin-bottom: 26px;
}
.competition_item {
  height: 120px;
}

h4.title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  padding: 0px 0 12px 2px;
}
h4.title img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
</style>
<style>
.recommend .van-list {
  padding: 0 14px;
}
.van-swipe-item {
  width: 100%;
}
.my-swipe .van-swipe__indicators {
  right: 20px;
  bottom: 12px !important;
  left: auto !important;
  transform: translate(0) !important;
}
.my-swipe .van-swipe__indicator.van-swipe__indicator--active {
  width: 16px;
  height: 6px;
  border-radius: 4px;
}
.my-swipe /deep/ .van-swipe__indicator {
  width: 6px;
  height: 6px;
  background: #fff;
  opacity: 0.6;
}
</style>
