const loginModules = {
  state: {
    isLogin: false, //是否登录
  },
  mutations: {
    UPDATE_LOGIN_STATUS(state, isLogin) {
      state.isLogin = isLogin;
    },
  },
  actions: {
    updateLoginStatus({ commit }, bol) {
      commit("UPDATE_LOGIN_STATUS", bol);
    },
  },
};
export default loginModules;
