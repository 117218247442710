export const emojiMap = {
  "[awkward]": require("../assets/img/emoji/[awkward].png"),
  "[angry]": require("../assets/img/emoji/[angry].png"),
  "[applause]": require("../assets/img/emoji/[applause].png"),
  "[awesome]": require("../assets/img/emoji/[awesome].png"),
  "[bah]": require("../assets/img/emoji/[bah].png"),
  "[beer]": require("../assets/img/emoji/[beer].png"),
  "[birthday]": require("../assets/img/emoji/[birthday].png"),
  "[blackface]": require("../assets/img/emoji/[blackface].png"),
  "[blood]": require("../assets/img/emoji/[blood].png"),
  "[blowkisses]": require("../assets/img/emoji/[blowkisses].png"),
  "[bluedog]": require("../assets/img/emoji/[bluedog].png"),
  "[bomb]": require("../assets/img/emoji/[bomb].png"),
  "[broken]": require("../assets/img/emoji/[broken].png"),
  "[bye]": require("../assets/img/emoji/[bye].png"),
  "[caress]": require("../assets/img/emoji/[caress].png"),
  "[chuckle]": require("../assets/img/emoji/[chuckle].png"),
  "[cigarette]": require("../assets/img/emoji/[cigarette].png"),
  "[clap]": require("../assets/img/emoji/[clap].png"),
  "[cleaver]": require("../assets/img/emoji/[cleaver].png"),
  "[comeon]": require("../assets/img/emoji/[comeon].png"),
  "[concerned]": require("../assets/img/emoji/[concerned].png"),
  "[cool]": require("../assets/img/emoji/[cool].png"),
  "[coolguy]": require("../assets/img/emoji/[coolguy].png"),
  "[cry]": require("../assets/img/emoji/[cry].png"),
  "[cucumber]": require("../assets/img/emoji/[cucumber].png"),
  "[dazzler]": require("../assets/img/emoji/[dazzler].png"),
  "[dizzy]": require("../assets/img/emoji/[dizzy].png"),
  "[doge]": require("../assets/img/emoji/[doge].png"),
  "[done]": require("../assets/img/emoji/[done].png"),
  "[drowsy]": require("../assets/img/emoji/[drowsy].png"),
  "[earphone]": require("../assets/img/emoji/[earphone].png"),
  "[eighteen]": require("../assets/img/emoji/[eighteen].png"),
  "[embarrasse]": require("../assets/img/emoji/[embarrasse].png"),
  "[facepalm]": require("../assets/img/emoji/[facepalm].png"),
  "[follow]": require("../assets/img/emoji/[follow].png"),
  "[following]": require("../assets/img/emoji/[following].png"),
  "[funny]": require("../assets/img/emoji/[funny].png"),
  "[gift]": require("../assets/img/emoji/[gift].png"),
  "[gog]": require("../assets/img/emoji/[gog].png"),
  "[graydog]": require("../assets/img/emoji/[graydog].png"),
  "[greenhat]": require("../assets/img/emoji/[greenhat].png"),
  "[grimace]": require("../assets/img/emoji/[grimace].png"),
  "[grin]": require("../assets/img/emoji/[grin].png"),
  "[hammer]": require("../assets/img/emoji/[hammer].png"),
  "[happy]": require("../assets/img/emoji/[happy].png"),
  "[heart]": require("../assets/img/emoji/[heart].png"),
  "[hug]": require("../assets/img/emoji/[hug].png"),
  "[kiss]": require("../assets/img/emoji/[kiss].png"),
  "[kissheart]": require("../assets/img/emoji/[kissheart].png"),
  "[kisskiss]": require("../assets/img/emoji/[kisskiss].png"),
  "[like]": require("../assets/img/emoji/[like].png"),
  "[lip]": require("../assets/img/emoji/[lip].png"),
  "[lol]": require("../assets/img/emoji/[lol].png"),
  "[mask]": require("../assets/img/emoji/[mask].png"),
  "[mighty]": require("../assets/img/emoji/[mighty].png"),
  "[money]": require("../assets/img/emoji/[money].png"),
  "[mouse]": require("../assets/img/emoji/[mouse].png"),
  "[nosepick]": require("../assets/img/emoji/[nosepick].png"),
  "[onlooker]": require("../assets/img/emoji/[onlooker].png"),
  "[panic]": require("../assets/img/emoji/[panic].png"),
  "[please]": require("../assets/img/emoji/[please].png"),
  "[pooh]": require("../assets/img/emoji/[pooh].png"),
  "[poop]": require("../assets/img/emoji/[poop].png"),
  "[powder]": require("../assets/img/emoji/[powder].png"),
  "[puke]": require("../assets/img/emoji/[puke].png"),
  "[punch]": require("../assets/img/emoji/[punch].png"),
  "[rainbow]": require("../assets/img/emoji/[rainbow].png"),
  "[refuse]": require("../assets/img/emoji/[refuse].png"),
  "[respect]": require("../assets/img/emoji/[respect].png"),
  "[rose]": require("../assets/img/emoji/[rose].png"),
  "[sad]": require("../assets/img/emoji/[sad].png"),
  "[scary]": require("../assets/img/emoji/[scary].png"),
  "[scowl]": require("../assets/img/emoji/[scowl].png"),
  "[scream]": require("../assets/img/emoji/[scream].png"),
  "[scrolleye]": require("../assets/img/emoji/[scrolleye].png"),
  "[shake]": require("../assets/img/emoji/[shake].png"),
  "[shhh]": require("../assets/img/emoji/[shhh].png"),
  "[shocked]": require("../assets/img/emoji/[shocked].png"),
  "[shutup]": require("../assets/img/emoji/[shutup].png"),
  "[shy]": require("../assets/img/emoji/[shy].png"),
  "[sixsixsix]": require("../assets/img/emoji/[sixsixsix].png"),
  "[sleep]": require("../assets/img/emoji/[sleep].png"),
  "[sly]": require("../assets/img/emoji/[sly].png"),
  "[smile]": require("../assets/img/emoji/[smile].png"),
  "[smiletears]": require("../assets/img/emoji/[smiletears].png"),
  "[smirk]": require("../assets/img/emoji/[smirk].png"),
  "[speechless]": require("../assets/img/emoji/[speechless].png"),
  "[sweats]": require("../assets/img/emoji/[sweats].png"),
  "[tangled]": require("../assets/img/emoji/[tangled].png"),
  "[tears]": require("../assets/img/emoji/[tears].png"),
  "[terror]": require("../assets/img/emoji/[terror].png"),
  "[think]": require("../assets/img/emoji/[think].png"),
  "[thumb]": require("../assets/img/emoji/[thumb].png"),
  "[thumbsup]": require("../assets/img/emoji/[thumbsup].png"),
  "[toasted]": require("../assets/img/emoji/[toasted].png"),
  "[tongue]": require("../assets/img/emoji/[tongue].png"),
  "[tooth]": require("../assets/img/emoji/[tooth].png"),
  "[trick]": require("../assets/img/emoji/[trick].png"),
  "[whimper]": require("../assets/img/emoji/[whimper].png"),
  "[win]": require("../assets/img/emoji/[win].png"),
  "[wronged]": require("../assets/img/emoji/[wronged].png"),
  "[yawn]": require("../assets/img/emoji/[yawn].png"),
};
// export function emojiMap(name) {
//   return new URL(`../assets/img/emoji/${name}.png`, import.meta.url).href;
// }
export const emojiName = [
  "[angry]",
  "[awkward]",
  "[applause]",
  "[awesome]",
  "[bah]",
  "[beer]",
  "[birthday]",
  "[blackface]",
  "[blood]",
  "[blowkisses]",
  "[bluedog]",
  "[bomb]",
  "[broken]",
  "[bye]",
  "[caress]",
  "[chuckle]",
  "[cigarette]",
  "[clap]",
  "[cleaver]",
  "[comeon]",
  "[concerned]",
  "[cool]",
  "[coolguy]",
  "[cry]",
  "[cucumber]",
  "[dazzler]",
  "[dizzy]",
  "[doge]",
  "[done]",
  "[drowsy]",
  "[earphone]",
  "[eighteen]",
  "[embarrasse]",
  "[facepalm]",
  "[follow]",
  "[following]",
  "[funny]",
  "[gift]",
  "[gog]",
  "[graydog]",
  "[greenhat]",
  "[grimace]",
  "[grin]",
  "[hammer]",
  "[happy]",
  "[heart]",
  "[hug]",
  "[kiss]",
  "[kissheart]",
  "[kisskiss]",
  "[like]",
  "[lip]",
  "[lol]",
  "[mask]",
  "[mighty]",
  "[money]",
  "[mouse]",
  "[nosepick]",
  "[onlooker]",
  "[panic]",
  "[please]",
  "[pooh]",
  "[poop]",
  "[powder]",
  "[puke]",
  "[punch]",
  "[rainbow]",
  "[refuse]",
  "[respect]",
  "[rose]",
  "[sad]",
  "[scary]",
  "[scowl]",
  "[scream]",
  "[scrolleye]",
  "[shake]",
  "[shhh]",
  "[shocked]",
  "[shutup]",
  "[shy]",
  "[sixsixsix]",
  "[sleep]",
  "[sly]",
  "[smile]",
  "[smiletears]",
  "[smirk]",
  "[speechless]",
  "[sweats]",
  "[tangled]",
  "[tears]",
  "[terror]",
  "[think]",
  "[thumb]",
  "[thumbsup]",
  "[toasted]",
  "[tongue]",
  "[tooth]",
  "[trick]",
  "[whimper]",
  "[win]",
  "[wronged]",
  "[yawn]",
];
